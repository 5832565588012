.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.admin-table {
    width: 100%;
    border-collapse: collapse;
}

.admin-table th, .admin-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.admin-table th {
    background-color: #f2f2f2;
}

.admin-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

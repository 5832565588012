.RSVPForm {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
  }
  
  .RSVPForm h2 {
    text-align: center;
    color: #333;
  }
  
  .RSVPForm p {
    font-size: 14px;
    line-height: 1.5;
    color: #666;
  }
  
  .RSVPForm form {
    width: 100%;
  }
  
  .RSVPForm input[type="text"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .RSVPForm input[type="checkbox"] {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .RSVPForm button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .RSVPForm button:hover {
    background-color: #0056b3;
  }
  
  .plusOneInputHidden {
    display: none;
  }
  
  .plusOneCheckbox label {
    align-items: center;
  }
  
  .plusOneCheckbox input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .map-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .thank-you-message {
    margin-top: 20px; /* Adjust margin as needed */
    color: green; /* Change color to your preference */
    font-weight: bold; /* Apply bold font weight */
}
